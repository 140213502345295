import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import Resumable from "resumablejs";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

// @dynamic
@Injectable({ providedIn: "root" })
export class UploadService {
	constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

	uploadImage(file: File, img_catid: number): Observable<any> {
		const formData = new FormData();
		formData.append("files[0]", file, file.name);
		formData.append("img_catid", img_catid.toString());
		return this.http.post("/api/image-library/v1/images", formData);
	}

	uploadImageUrl(urls: string[], img_catid: number): Observable<any> {
		const formData = new FormData();
		for (let i = 0; i < urls.length; i++) formData.append(`urls[${i}]`, urls[i]);
		formData.append("img_catid", img_catid.toString());
		return this.http.post("/api/image-library/v1/images", formData);
	}

	uploadFile(file: File, file_catid: number) {
		return new Observable<IFile>((sub) => {
			const resumable = new Resumable({
				target: "/api/crud/file/chunkUpload",
				chunkSize: 1024 * 1024,
				simultaneousUploads: 4,
				testMethod: "POST",
				testChunks: false,
				query: {
					file_catid,
				},
			});
			resumable.on("fileSuccess", (file: any, event: any) => {
				event = JSON.parse(event);
				if (!event.success) {
					console.error(event.message);
					sub.error(event.message);
				} else {
					sub.next({
						...event.file,
						trustedSrc: this.sanitizer.bypassSecurityTrustResourceUrl(
							"https://dms.rvimg.com" + event.file.file_dir + event.file.filename,
						),
					});
				}
				sub.complete();
			});
			resumable.on("error", (message: string) => {
				console.error(message);
				sub.error(message);
				sub.complete();
			});
			resumable.on("filesAdded", () => {
				resumable!.upload();
			});
			resumable!.addFiles([file]);
		});
	}

	uploadAv(file: File, av_catid: number) {
		return new Observable<IAv>((sub) => {
			const resumable = new Resumable({
				target: "/api/crud/av/chunkUpload",
				chunkSize: 1024 * 1024,
				simultaneousUploads: 4,
				testMethod: "POST",
				testChunks: false,
				query: {
					av_catid,
				},
			});
			resumable.on("fileSuccess", (file: any, event: any) => {
				event = JSON.parse(event);
				if (!event.success) {
					console.error(event.message);
					sub.error(event.message);
				} else {
					sub.next({
						...event.av,
						trustedSrc: this.sanitizer.bypassSecurityTrustResourceUrl(
							"https://dms.rvimg.com" + event.av.av_file_path,
						),
					});
				}
				sub.complete();
			});
			resumable.on("error", (message: string) => {
				console.error(message);
				sub.error(message);
				sub.complete();
			});
			resumable.on("filesAdded", () => {
				resumable!.upload();
			});
			resumable!.addFiles([file]);
		});
	}
}

export interface IFile {
	fileid: number;
	file: string;
	filename: string;
	file_dir: string;
	site_configid: number;
	file_catid: number;
	updated_at: string;
	added_at: string;
	ready: boolean;
	trustedSrc: SafeResourceUrl;
}

export interface IAv {
	avid: number;
	av: string;
	av_file_path: string;
	site_configid: number;
	av_catid: number;
	updated_at: string;
	added_at: string;
	ready: boolean;
	trustedSrc: SafeResourceUrl;
}
